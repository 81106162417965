import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, BreadCrumb, MarkdownContent, MarkdownContentWrapper, MarkdownContentWrapperInner, MarkdownContentTitle, ComingSoon } from '../components/Section'
import PrimaryLinkButton from '../components/Button/PrimaryLinkButton'
import { Link } from "gatsby"
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import BreakpointDown from '../components/Media/BreakpointDown'


const GroceryTitle = styled.h1`
`
const GroceryRow = styled.div`
  display:flex;
  margin-left:-15px;
  margin-right:-15px;
  flex-wrap:wrap;
  justify-content:space-between;
  margin-top:20px;
`
const GroceryColumn = styled.div`
 flex:0 0 50%;
 width:50%;
 padding-left:15px;
 padding-right:15px;
 ${BreakpointDown.xl`
  flex:0 0 100%;
  width:100%; 
 `}
 .gatsby-image-wrapper{ width:100%;}
 &:first-child{
   padding-right:60px;
   ${BreakpointDown.xl`
      flex:0 0 100%;
      width:100%;
      padding-right:15px;
    `}
 }
 &:last-child{
   padding-top:40px;
   ${BreakpointDown.md`
     padding-top:0px;
   `}
 }
`


// GroceryIndustry
const SectionBoxShadow = styled(Section)`
  box-shadow: 0px 20px 85px #00000033;
  z-index:2;
`
const GroceryIndustryTitle = styled.h2`
  max-width: 768px;
  text-align:center;
  margin-left:auto;
  margin-right:auto;
`

const GroceryIndustryList = styled.div`
 display:flex;
 flex-wrap:wrap;
 margin-top:60px;
 border-bottom:1px solid #CCCCCC;
 padding-bottom:60px;
 ${BreakpointDown.xl`
   margin-top:30px;
   padding-bottom:30px;
 `}
 &:nth-child(odd){
   flex-direction:row-reverse;
   &:last-child{border-bottom:0; padding-bottom:0;}
   .industry-left{
  &:before{
    right:auto;
    margin-right:0;
    left: 0;
    top: 35px;
    margin-left: -60px;
  }
   }
   .industry-right{
    padding-right: 90px;
    padding-left:0;
    ${BreakpointDown.xl`
      padding-right: 0px;
    `}
   }
 }
`
const GroceryIndustryListLeft = styled.div`
flex:0 0 600px;
position:relative;
${BreakpointDown.xl`
 flex:0 0 100%;
`}
&:before{
  content:'';
  width:120px;
  height:4px;
  background:#000;
  position:absolute;
  right:0;
  top:35px;
  margin-right:-60px;
  z-index:1;
  ${BreakpointDown.xl`
     display:none;
  `}
}
`
const GroceryIndustryListRight = styled.div`
  flex:1;
  padding-left:90px;
  padding-top:20px;
  ${BreakpointDown.xl`
    padding-left: 0px;
  `}
    ${BreakpointDown.md`
     padding-top:10px;
  `}
`
const GroceryIndustryListRightTitle = styled.div`
${BreakpointDown.md`
  margin-bottom:0;
`}
`
const GroceryIndustryListRightContent = styled.div`
 p{
   font-style:normal;
 }
`
const TraingleShape = styled.div`
  width: 0;
  height: 0;
  display: block;
  border-top: 20px transparent solid;
  border-right: 20px transparent solid;
  border-left: 20px transparent solid;
  border-bottom: 20px #fff solid;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  margin-left: auto;
  margin-right: auto;
  z-index: 3;
`

function MedicalPage() {
  return (
    <Layout pageName="industry">
      <SEO
        title="Business Technology Solutions Hospitals and Medical Care Facilities"
        description="Streamline your medical operations with WYN Technologies' end-to-end access control, IT support, camera surveillance, and body temperature monitoring solutions. Start today."/> 
      <Section pt="174px" pb="80px" bgColor="#F8F8F8" xpt="120px" xpb="60px" mpt="90px" mpb="40px">
        <TraingleShape />
        <div className="container">
          <BreadCrumb>
            <Link to="/">Home</Link><Link to="/#">Medical</Link>
          </BreadCrumb>
          <GroceryRow>
            <GroceryColumn>
              <GroceryTitle>Business Technology Solutions for Medical Providers</GroceryTitle>
              <p>Leading a hospital or medical care facility can certainly be challenging in terms of managing all the necessary moving parts and logistics. Thankfully, there are many modern technological solutions to help streamline your organization and make everything run much smoother. And we’re not just talking about medical technology, either; there are many helpful applications of business technology which can help you move the needle forward, too.</p>
              <p><strong>WYN Technologies</strong> can install whatever types of technology solutions you’re looking for, and we can come alongside to provide needed IT support services, too. Keep reading to learn more about what we have to offer you and your healthcare organization!</p>
            </GroceryColumn>
            <GroceryColumn>
              <StaticImage
                src="../images/industry-1.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Brands We Trust"
                placeholder="blurred"
              />
            </GroceryColumn>
          </GroceryRow>
        </div>
      </Section>
      <SectionBoxShadow bgColor="#F8F8F8" pt="80px" pb="80px" xpt="60px" xpb="60px" mpt="40px" mpb="40px">
        <div className="container">
          <GroceryIndustryTitle className="h1">Some of the Services We Offer for Healthcare Providers</GroceryIndustryTitle>
          <GroceryIndustryList>
              <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/our-service-2.jpg"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">Voice and Data Cabling, Including Fiber Network Options</GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                  <p>Having the right voice/data cabling network is crucial to the success of any modern business or organization, and hospitals and medical providers are certainly no exception. Need some remediation of your current cabling network? We can help. In addition, <strong>WYN</strong> specializes in designing and installing new structured cabling, racking, and data closet solutions for any commercial application.</p>
                  <PrimaryLinkButton text="View Full Detail" />
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList> 
           <GroceryIndustryList>
              <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/our-service-3.png"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">WiFi Access Points & Heat Mapping Services</GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                  <p>These days it’s really not enough to have a good structured cabling system in place. You also need access to an effective wireless network, for the benefit of your healthcare staff as well as for the convenience of your patients and visitors. And <strong>WYN Technologies</strong> can design and install your wireless access points for you! In addition, we can use wireless heat mapping to identify and correct any potential problem areas with your current WiFi coverage.</p>
                  <PrimaryLinkButton text="View Full Detail" />
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList>
                  <GroceryIndustryList>
              <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/our-service-7.png"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">VoIP Phone Systems</GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                  <p>Every hospital and healthcare provider needs reliable phone service, for people calling in, people who need to call out, and for room-to-room communication – the health and safety of your patients literally depends on it. Older phone systems don’t offer many of the features which are now available with modern systems, plus they’re typically more expensive to operate & maintain. Upgrading to a VoIP-based system just makes good sense for your organization. Reach out to us at <strong>WYN</strong> today, and let us provide you with a better solution.</p>
                  <PrimaryLinkButton text="View Full Detail" />
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList>
            <GroceryIndustryList>
              <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/our-service-3.png"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">Body Temperature Remote Monitoring Stations</GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                  <p>Digital camera technology can provide for more than just general surveillance, too. Many healthcare providers – along with schools and other businesses – are taking advantage of thermal imaging solutions to help screen for fevers and elevated body temperatures which can accompany the onset of COVID-19 or other threatening diseases. Remote body temperature monitoring technology is an effective, touchless way to assess patients, visitors, and staff instantly, and  <strong>WYN</strong> can install the right thermal camera system to meet your needs.</p>
                  <PrimaryLinkButton text="View Full Detail" />
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList>
           <GroceryIndustryList>
              <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/our-service-7.png"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">Access Control Technology</GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                  <p>Of course, another important security concern for hospitals and medical providers is governing who has access to your building and when, both inside and outside. And when it comes to key staff entry points, it’s nice to be able to provide them with some good, keyless access solutions. Whether you prefer PIN pads, proximity cards, keychain fobs, mobile device connectivity, biometric security features, or a combination of these – <strong>WYN</strong> can provide and install whatever configuration you like.</p>
                  <PrimaryLinkButton text="View Full Detail" />
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList>
          <GroceryIndustryList>
              <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/our-service-12.jpg"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">IT Support Services</GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                  <p>Wish you had some business technology IT support for your healthcare facility? Well, you’ve got it, if you choose to partner with <strong>WYN Technologies</strong>! We’re here whenever you may need us, and we’re pleased to offer some helpful service-after-the-sale contracts to help keep your equipment running smoothly for you, too. Running a healthcare business is stressful enough – let us take care of your business technology worries!</p>
                  <Link to="/"><PrimaryLinkButton text="View Full Detail"></PrimaryLinkButton></Link>
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList>
           <GroceryIndustryList>
                <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/our-service-12.jpg"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">Moves, Adds, & Changes (MAC Services)</GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                  <p>The healthcare industry is always growing & expanding, and so is your business. Remodeling your current facility, or planning a move to a new location? <strong>WYN</strong> can help things go much more smoothly for you by providing de-installation services for your existing network and/or legacy equipment, along with relocation and new installation services. We can set up any structured cabling or wireless network access points you may need too, along with any other business technology needs you may have.</p>
                  <Link to="/"><PrimaryLinkButton text="View Full Detail"></PrimaryLinkButton></Link>
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList>
            <GroceryIndustryList>
                <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/our-service-12.jpg"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">Equipment Staging, Warehousing, & Storage Services</GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                  <p><strong>WYN</strong> has a complete staging facility for getting your new equipment set up and ready-to-go, thus making the installation process even smoother and more painless for you. We also have a large, security-monitored warehouse, should you have any other equipment storage needs. We also offer depot repair services, should you wish to go with a refurbished (and more easily affordable) equipment option for any designated applications.</p>
                  <Link to="/"><PrimaryLinkButton text="View Full Detail"></PrimaryLinkButton></Link>
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList>
          </div>
      </SectionBoxShadow>
      <Section pt="100px" pb="100px" xpt="60px" xpb="60px" mpt="40px" mpb="40px" bgColor="#F8F8F8">
        <div className="container">
          <MarkdownContentWrapper>
            <MarkdownContentWrapperInner>
              <MarkdownContentTitle>
                {/* <h2 className="h1">Content Heading</h2> */}
              </MarkdownContentTitle>
            {/*  <ComingSoon textAlign="center">Coming Soon...</ComingSoon> */}
               <MarkdownContent>
                  <h2>GPS Tracking Solutions</h2>
                  <p>If you’re a hospital, the chances are good that you also utilize designated forms of patient transportation which may include ambulances, along with other types of vehicles that benefit your practice. Would you like to be able to keep a better account of vehicle location and ETA to your facility, along with the ability to track things like mileage costs? Let <strong>WYN</strong> install a vehicle GPS tracking system that can better serve these needs, and more. Just get in touch with us today at <a href="tel:3368990555">(336) 899-0555</a> to explore the many features and possibilities that come along with implementing the right GPS tracking solution.</p>
               </MarkdownContent>
               <MarkdownContent>
                    <h2>Why Choose WYN Technologies for Your Medical Business Technology Needs</h2>
                  <ul>
                    <li>
                    <strong>Tested-and-Approved Custom Solutions for You</strong>
                    <p>At <strong>WYN</strong>, we’re specially equipped to be able to fully stage, configure, and test your chosen devices and products ahead of time in our dedicated staging facility, before we ever come out to install.</p>
                    </li>
                    <li>
                    <strong>Only the Highest-Quality Products & Services </strong>
                    <p><strong>WYN Technologies</strong> only partners with the best business technology brands and manufacturers. And our level of installation workmanship and ongoing personalized service for our clients is really second to none!</p>
                    </li>
                    <li>
                    <strong>We Know How to Work Within Your Budget</strong>
                    <p><strong>WYN</strong> is ultimately here to help your business succeed. We understand corporate budgets & finances, and we can deliver the best technological solution to meet both your needs and your budget!</p>
                    </li>
                    <li>
                    <strong>Fast, Responsive 24/7 Customer Support</strong>
                    <p>Unlike some other technology providers, <strong>WYN</strong> is committed to being here for you for the long run. We actually answer the phone when you call, and we’ll respond promptly to your needs 24/7.</p>
                    </li>
                    </ul>
                </MarkdownContent>
             <MarkdownContent>
                <h2>Got Questions? Ready to Get Started?</h2>
                <p>Need to plan some needed business technology upgrades for your hospital or medical facility? <strong>WYN Technologies</strong> is happy to come out and provide you with a free initial site evaluation and consultation. Have some other specific questions? Ready to get started with your best technology solutions? Connect with us today at <a href="tel:3368990555">(336) 899-0555</a>, and let’s have a conversation about how WYN can best meet your needs!</p>
              </MarkdownContent>
            </MarkdownContentWrapperInner>
          </MarkdownContentWrapper>
        </div>
      </Section>
    </Layout>
  )
}
export default MedicalPage
